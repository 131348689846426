<template>
  <div>
    <!-- 頭部組件 -->
    <nav-header :type="1"></nav-header>

    <div class="app-container microphoneDetection">
      <div class="box">
        <!-- 步驟 -->
        <div class="box-setp">
          <div class="box-setp-div active">
            <img class="box-setp-div-img" src="@/assets/image/test/step1.png" alt="第一步">
            <p class="box-setp-div-p">耳機測試</p>
          </div>
          <div class="box-setp-arrow active">
            <i class="box-setp-arrow-left"></i>
            <img class="box-setp-arrow-img" src="@/assets/image/test/arrow.png" alt="">
            <i class="box-setp-arrow-right"></i>
          </div>
          <div class="box-setp-div active">
            <img class="box-setp-div-img" src="@/assets/image/test/step2.png" alt="第二步">
            <p class="box-setp-div-p">麥克風測試</p>
          </div>
          <div class="box-setp-arrow">
            <i class="box-setp-arrow-left"></i>
            <img class="box-setp-arrow-img" src="@/assets/image/test/arrows.png" alt="">
            <i class="box-setp-arrow-right"></i>
          </div>
          <div class="box-setp-div">
            <img class="box-setp-div-img" src="@/assets/image/test/steps3.png" alt="第三步">
            <p class="box-setp-div-p">鏡頭測試</p>
          </div>
          <div class="box-setp-arrow">
            <i class="box-setp-arrow-left"></i>
            <img class="box-setp-arrow-img" src="@/assets/image/test/arrows.png" alt="">
            <i class="box-setp-arrow-right"></i>
          </div>
          <div class="box-setp-div">
            <img class="box-setp-div-img" src="@/assets/image/test/steps4.png" alt="第四步">
            <p class="box-setp-div-p">網速測試</p>
          </div>
          <div class="box-setp-arrow">
            <i class="box-setp-arrow-left"></i>
            <img class="box-setp-arrow-img" src="@/assets/image/test/arrows.png" alt="">
            <i class="box-setp-arrow-right"></i>
          </div>
          <div class="box-setp-div">
            <img class="box-setp-div-img" src="@/assets/image/test/steps5.png" alt="第五步">
            <p class="box-setp-div-p">螢幕分享</p>
          </div>
        </div>

        <!-- 內容 -->
        <div class="box-content">
          <div class="box-content-left">
            <img src="@/assets/image/test/detection2.png" alt="麥克風測試">
          </div>
          <div class="box-content-right">
            <h1 class="box-content-right-h1">
              麥克風測試<span>（口說測驗）</span>
            </h1>
            <h2 class="box-content-right-h2">
              請點擊下方錄音鍵<img src="@/assets/image/test/icon-stop.png" alt="錄音">，
            </h2>
            <h2 class="box-content-right-h2">
              並於 15 秒內唸出自己的准考證號碼 3 次。
            </h2>
            <h2 class="box-content-right-h2">
              完成錄音後請點擊播放鍵<img src="@/assets/image/test/icon-play.png" alt="播放">，
            </h2>
            <h2 class="box-content-right-h2">
              確認是否成功錄製自己的聲音。
            </h2>
            <div class="box-content-right-div">
              <div class="div-left">
                <img @click="handleStart" src="@/assets/image/test/icon-stops.png" alt="">
              </div>
              <div class="div-left" v-if="flagStatus != 0">
                <img @click="playAudio" src="@/assets/image/test/icon-plays.png" alt="">
              </div>
              <div class="div-right" :style="{ width: flagStatus != 0 ? 'calc(100% - 140px)' : 'calc(100% - 70px)' }">
                <div class="div-right-time">
                  <span v-if="flagStatus != 2">{{ formatTime(recorder.duration) || '00:00' }}</span>
                  <span v-if="flagStatus == 2">{{ formatTime(this.playTime) || '00:00' }}</span>
                  <span>{{ formatTime(duration) || '00:00' }}</span>
                </div>
                <div class="div-right-progress">
                  <div class="progress-round" :style="{ left: `${percentage - 1}%` }"></div>
                  <el-progress :percentage="percentage" :show-text="false"></el-progress>
                </div>
              </div>
            </div>
            <p class="box-content-right-p">
              <img src="@/assets/image/test/icon-warn.png" alt="提示">
              請考生務必確認錄音內容清楚，以免影響評分。
            </p>
            <div class="box-content-right-button"> 
              <div class="button1" @click="openQuestion">有問題請點我</div>
              <div class="button2" @click="next">
                NEXT
                <img src="@/assets/image/test/icon-next.png" alt="">
              </div>
            </div>
          </div>
        </div>

        <!-- 提醒 -->
        <div class="box-remind">
          <img src="@/assets/image/test/remind.png" alt="提醒">
          提醒：若無法排除問題，請加入line@帳號:@688qwier，由客服為您服務。
        </div>
      </div>
    </div>
    
    <!-- 問題彈窗 -->
    <detection-popup v-model="popupShow" type="microphone"></detection-popup>

    <!-- 底部組件 -->
    <nav-footer></nav-footer>
  </div>
</template>

<script>
import Recorder from 'js-audio-recorder'
import DetectionPopup from './components/DetectionPopup.vue'
export default {
  name: 'MicrophoneDetection',
  components: { DetectionPopup },
  data() {
    return {
      popupShow: false,

      // 录音实例
      recorder: null,
      // 限制15秒时长
      duration: 15,
      // 播放/录音状态 0未开始录音  1录音  2播放
      flagStatus: 0,
      // 录音播放时长
      playTime: 0,
      // 进度条
      percentage: 0,
      // 录音状态 true正在录音 false录音完成/未开始录音
      recordStatus: false,
      // 播放状态 true正在播放 false播放完成/未开始播放
      playStatus: false,
    }
  },
  created() {
    this.recorder = new Recorder()
  },
  methods: {
    // 开始录音
    handleStart() {
      // 正在录音 播放时不允许点击
      if (this.recordStatus || this.playStatus) return
      this.recorder = new Recorder()
      Recorder.getPermission().then(() => {
        // 开始录音
        this.recorder.start()
        // 开启录音倒计时
        this.recordCountTime()
        // 展示播放按钮
        this.flagStatus = 0
        // 录音状态改为正在录音
        this.recordStatus = true
      }, (error) => {
        this.$message({
          message: '請允許該網頁使用麥克風',
          type: 'info'
        })
        console.log(`${error.name} : ${error.message}`)
      })
    },
    // 录音倒计时
    recordCountTime() {
      // 进度条处理
      let percentage = Number(this.recorder.duration / this.duration * 100)
      this.percentage = percentage >= 100 ? 100 : percentage
      // 倒计时
      let timeNum = Math.floor(this.recorder.duration)
      if (timeNum >= this.duration) {
        // 停止录音
        this.recorder.stop()
        // 展示播放按钮
        this.flagStatus = 1
        // 录音状态改为录音完成
        this.recordStatus = false
      } else {
        setTimeout(this.recordCountTime, 100)
      }
    },


    // 播放录音
    playAudio() {
      // 正在录音 播放时不允许点击
      if (this.recordStatus || this.playStatus) return
      this.recorder.play()
      // 播放状态改为正在播放
      this.playStatus = true
      this.flagStatus = 2
      this.percentage = 0
      setTimeout(() => {
        this.playCountTime()
      }, 100)
    },
    // 播放倒计时
    playCountTime() {
      // 进度条处理
      let percentage = Number(this.recorder.getPlayTime() / this.duration * 100)
      this.percentage = percentage >= 100 ? 100 : percentage
      // 倒计时
      this.playTime = this.recorder.getPlayTime()
      let timeNum = Math.floor(this.playTime)
      if (timeNum >= this.duration) {
        // 停止播放
        this.recorder.stopPlay()
        // 播放状态改为播放完成
        this.playStatus = false
      } else {
        setTimeout(this.playCountTime, 100)
      }
    },
    
    // 时间处理
    formatTime(time) {
      // 有可能没获取到，为NaN
      if (!time) return '00:00'
      let min = Math.floor(time / 60).toString().padStart(2, '0')
      let sec = Math.floor(time % 60).toString().padStart(2, '0')
      return `${min}:${sec}`
    },


    // 有問題請點我
    openQuestion() {
      this.popupShow = true
    },
    // 下一步
    next() {
      this.$router.push({
        path: '/shotDetection'
      })
    },
  }
}
</script>

<style lang="less" scoped>
.microphoneDetection {
  .box {
    margin: 20px auto;
    width: 1320px;
    .box-setp {
      display: flex;
      justify-content: center;
      .box-setp-div {
        text-align: center;
        .box-setp-div-img {
          margin-bottom: 11px;
          width: 60px;
          height: 60px;
        }
        .box-setp-div-p {
          font-family: "Microsoft JhengHei","微軟正黑體";
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 24px;
          text-align: center;
          letter-spacing: 0.01em;
          color: #989898;
        }
      }
      .box-setp-arrow {
        margin: 24px 0 0;
        padding: 0 60px;
        position: relative;
        width: 6px;
        height: 8px;
        .box-setp-arrow-img {
          width: 100%;
          height: 100%;
        }
        .box-setp-arrow-left {
          position: absolute;
          top: 6px;
          left: 0;
          margin: 0 4px;
          width: 50px;
          height: 0px;
          border: 1px dashed #D3D3D3;
        }
        .box-setp-arrow-right {
          position: absolute;
          top: 6px;
          right: 0;
          margin: 0 4px;
          width: 50px;
          height: 0px;
          border: 1px dashed #D3D3D3;
        }
      }
      .active {
        .box-setp-div-p {
          color: #0064D9;
        }
        .box-setp-arrow-left {
          border: 1px dashed #0665D8;
        }
        .box-setp-arrow-right {
          border: 1px dashed #0665D8;
        }
      }
    }

    .box-content {
      margin-top: 20px;
      padding: 50px 120px;
      width: calc(100% - 240px);
      height: 500px;
      min-height: calc(100vh - 444px);
      background: #FFFFFF;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
      border-radius: 8px;
      display: flex;
      align-items: center;
      .box-content-left {
        margin-right: 82px;
        width: 438px;
        height: 320px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .box-content-right {
        width: calc(100% - 520px);
        height: 100%;
        .box-content-right-h1 {
          margin-bottom: 20px;
          font-family: "Microsoft JhengHei","微軟正黑體";
          font-style: normal;
          font-weight: 700;
          font-size: 30px;
          line-height: 40px;
          color: #333333;
          span {
            font-size: 24px;
          }
        }
        .box-content-right-h2 {
          font-family: "Microsoft JhengHei","微軟正黑體";
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 32px;
          color: #333333;
          display: flex;
          align-items: center;
          img {
            margin-left: 8px;
            width: 24px;
            height: 24px;
          }
        }
        .box-content-right-div {
          margin: 30px 0 16px;
          padding: 16px 20px;
          width: 500px;
          background: #F5F7FD;
          border-radius: 8px;
          display: inline-flex;
          align-items: center;
          .div-left {
            cursor: pointer;
            margin-right: 16px;
            width: 54px;
            height: 54px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .div-right {
            width: calc(100% - 70px);
            .div-right-time {
              margin-bottom: 10px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-family: "Microsoft JhengHei","微軟正黑體";
              font-style: normal;
              font-weight: 400;
              font-size: 20px;
              line-height: 27px;
              color: #4F4F4F;
            }
            .div-right-progress {
              width: 100%;
              height: 12px;
              position: relative;
              .progress-round {
                position: absolute;
                top: -4px;
                width: 20px;
                height: 20px;
                z-index: 1;
                transform: translateX(-50%);
                border-radius: 50%;
                background: #4277FF;
                box-shadow: 0px 4px 4px rgba(8, 76, 155, 0.25), inset 0px 0px 12px rgba(147, 133, 235, 0.4);
              }
              /deep/ .el-progress-bar__outer {
                height: 12px !important;
                background-color: #D3D3D3;
              }
              /deep/ .el-progress-bar__inner {
                background-color: unset;
                background-image: linear-gradient(90deg, #266DD7 3.31%, #84B5FF 100%);
              }
            }
          }
        }
        .box-content-right-p {
          font-family: "Microsoft JhengHei","微軟正黑體";
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 32px;
          color: #E60012;
          display: flex;
          align-items: center;
          img {
            margin-right: 4px;
            width: 30px;
            height: 30px;
          }
        }
        .box-content-right-button {
          user-select: none;
          margin-top: 40px;
          display: flex;
          align-items: center;
          .button1 {
            cursor: pointer;
            margin-right: 22px;
            width: 200px;
            height: 58px;
            border: 1px solid #0C4FA2;
            border-radius: 8px;
            background: #FFFFFF;
            font-family: "Microsoft JhengHei","微軟正黑體";
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 24px;
            letter-spacing: 0.01em;
            color: #0C4FA2;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .button2 {
            cursor: pointer;
            width: 200px;
            height: 58px;
            border: 1px solid #0C4FA2;
            border-radius: 8px;
            background: #0C4FA2;
            box-shadow: 0px 4px 8px rgba(0, 73, 207, 0.3);
            border-radius: 8px;
            font-family: "Microsoft JhengHei","微軟正黑體";
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 24px;
            letter-spacing: 0.01em;
            color: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              margin-left: 6px;
              width: 30px;
              height: 30px;
            }
          }
        }
      }
    }

    .box-remind {
      margin-top: 20px;
      width: 100%;
      height: 48px;
      background: #FDF3EC;
      border-radius: 8px;
      font-family: "Microsoft JhengHei","微軟正黑體";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 27px;
      color: #DA6B29;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin-right: 10px;
        width: 20px;
        height: 20px;
      }
    }
  }
}
</style>